.about-home {
    background: #ccc;
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    color: rgba(255,255,255,.8);
    font-size: 3vw;
    text-shadow: 4px 4px rgb(46, 43, 43);
    &>.about-img {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background: url(~@/assets/about-head.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        user-select: none;
        &.name {
            color: #fccc;
            background-image: url(~@/assets/about-name.jpg);
        }
    }
    &>.about-content {
        height: 100vh;
        position: relative;
        background: url(~@/assets/about-content.jpg);
        color: rgba($color: #000000, $alpha: 0.8);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        &.name {
            background-image: url(~@/assets/about-content-name.jpg);
            color: black;
            .content-body {
                transform: none;
                background: rgba($color: white, $alpha: 0.4);
            }
            .text-box  {
                text-align: center;
                transform: none;
                margin: 0;
            }
            p {
                text-align: left;
            }
        }
        &>.content-body {
            position: absolute;
            width: 100%;
            height: 50%;
            bottom: 25%;
            text-align: center;
            background: rgba($color: #000000, $alpha: .1);
            transform-origin: center bottom;
            transform: skew(0,-8deg);
        }
        .text-box {
            display: inline-block;
            text-align: left;
            margin: 8vw 0 4vw;
            width: 90%;
            max-width: 990px;
            transform-origin: right bottom;
            transform: skew(0,8deg);
            &>h2 {
                // margin-top: 100px;
                font-size: 30px;
                text-shadow: 1px 0 0 #323232;
                letter-spacing: 0.3em;
                margin-bottom: 5px;
            }
            &>p {
                font-size: 20px;
                margin-bottom: 10px;
                line-height: 1.7;
                text-shadow: 1px 0 0 black;
            }
            a.btn {
                font-size: 1.5vw;
                letter-spacing: 0.3em;
                text-shadow: 1px 0 0 black;
                line-height: 2.8;
                display: inline-block;
                background: #eaeaea;
                color: #000000;
                border: 3px solid #000;
                text-decoration: none;
                white-space: nowrap;
                padding: 0 20px;
            }
        }
    }
    &>.footer {
        height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4vw;
        background-color: #c0b5aa;
        &.name {
            color: #c0b5aa;
            background-color: rgba(255,255,255,.8);
        }
    }
}